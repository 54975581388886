import LastIntegrationCard from './LastIntegrationCard';
import { Col, Row } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player';

export default function LastIntegrations(): JSX.Element {
  return (
    <>
      <Row gutter={[32, 32]} justify="center" style={{ marginBottom: 32 }}>
        <Col span={24}>
          <ReactPlayer
            playing
            muted
            loop
            url="https://youtu.be/q6jS9jpLkv8"
            controls={true}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </Col>
      </Row>
      <Row gutter={[32, 32]} justify="center">
        <Col>
          <LastIntegrationCard
            iconAlt="shopify"
            iconSrc="/shopify/icon.png"
            title="Shopify"
            description="Integrate your online webshop with your invoicing system"
            href="/hitch/shopify/teamleader"
          />
        </Col>

        <Col>
          <LastIntegrationCard
            iconAlt="teamleader"
            iconSrc="/teamleader/icon.png"
            title="Teamleader"
            description="Enrich your CRM"
            href="/hitch/teamleader/shopify"
          />
        </Col>
      </Row>
    </>
  );
}
