import { Form, AutoComplete, ColProps } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import hitches from '../../services/apps/hitches';

type SearchHitchProps = {
  name: string;
  label: string;
  labelCol: ColProps;
  wrapperCol: ColProps;
};

const SearchHitch = ({ labelCol, wrapperCol, name, label }: SearchHitchProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} name={name} label={label}>
      <AutoComplete
        allowClear
        options={hitches.map(({ name }) => ({ value: name }))}
        placeholder={
          <div style={{ float: 'left' }}>
            {formatMessage({
              defaultMessage: 'search your app',
              description: 'form/autocomplete/placeholder',
            })}
          </div>
        }
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  );
};
export default SearchHitch;
