import handleFetchResponse from '../../utils/handleFetchResponse';
import firebase from 'firebase/app';

export async function createForm(values: Record<string, any>, user?: firebase.User): Promise<void> {
  return sendForm('/public/forms', values, user);
}

export async function createSubscription(
  values: Record<string, any>,
  user?: firebase.User
): Promise<void> {
  return sendForm('/public/subscriptions', values, user);
}

async function sendForm(
  url: string,
  values: Record<string, any>,
  user?: firebase.User
): Promise<void> {
  const idToken = user ? await user.getIdToken() : null;

  const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(idToken ? { Authorization: 'Bearer ' + idToken } : {}),
    },
    body: JSON.stringify(values),
  });
  return (await handleFetchResponse(response)).id;
}
