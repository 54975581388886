import { withPublicWrapper } from '../components/public/Wrapper/withPublicWrapper';
import Intro from 'components/public/Intro/Intro';
import IntroBlock from 'components/public/Intro/IntroBlock';
import { Col, Button, Divider, Row, Card } from 'antd';
import NextImage from 'next/image';
import Link from 'next/link';
import LastIntegrations from '../components/public/integrations/overview/LastIntegrations';
import React from 'react';
import ContactForm from 'components/ContactForm';
import { FormattedMessage, useIntl } from 'react-intl';
import Head from 'next/head';
import { useContext } from 'react';
import firebase from 'firebase/app';

// import useProfile from 'components/private/hooks/useProfile';
// import robotPic from '../public/homepage/robot2.png';
import { ProfileContext } from 'components/context/ProfileProvider';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';

const Index = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const { user } = useContext(UserAuthenticationContext) as { user?: firebase.User };
  const { hikes } = useContext(ProfileContext);

  const isLoggedIn = user && user.uid && !user.isAnonymous;
  const hasHike = hikes && hikes.length > 0;

  // TODO JDI remove this!!
  // const { hikes } = useProfile();
  // console.log(hikes);
  // const testIds = ['testId1', 'testId2'];
  // // const [{ hikes }, { hikes: hikes2 }] = testIds.map((id) => useTests(id, user?.uid));
  // const { hikes } = useTests('testId2', user?.uid);

  // useEffect(() => {
  //   if (!user) {
  //     enableAnonymousLogin();
  //   }
  // }, [user]);

  return (
    <>
      <Head>
        <title>Hitch and Hike - Home</title>
      </Head>
      <Intro
        title={formatMessage({
          defaultMessage: 'Sync your sales',
          description: 'homepage/title',
        })}
        image={{ src: '/logo.png', alt: 'logo', height: '1608', width: '1779' }}
        descriptionText={formatMessage({
          defaultMessage: 'Streamline online and offline sales',
          description: 'homepage/description',
        })}
      />
      {isLoggedIn && !hasHike && (
        <IntroBlock
          type="white"
          title={formatMessage({
            defaultMessage: 'Shopify-Teamleader connection',
            description: 'homepage/navigate/continue',
          })}
          description={
            <Col>
              <p>
                <FormattedMessage
                  defaultMessage="Further continue setting up your integration"
                  description="homepage/description/p1"
                />
              </p>
              <Link href={`hitch/shopify/teamleader`}>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    margin: 32,
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Continue setup"
                    description="homepage/description/link"
                  />
                </Button>
              </Link>
            </Col>
          }
        />
      )}
      {isLoggedIn && hasHike && (
        <IntroBlock
          type="white"
          title={formatMessage({
            defaultMessage: 'Shopify-Teamleader connection',
            description: 'homepage/navigate/dashboard',
          })}
          description={
            <Col>
              <p>
                <FormattedMessage
                  defaultMessage="Check how your integration is doing"
                  description="homepage/description/integration"
                />
              </p>
              <Link href={`dashboard`}>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    margin: 32,
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Go to Dashboard"
                    description="homepage/description/link"
                  />
                </Button>
              </Link>
            </Col>
          }
        />
      )}
      {!isLoggedIn && (
        <IntroBlock
          type="white"
          title={formatMessage({
            defaultMessage: 'Shopify-Teamleader connection',
            description: 'homepage/navigate/dashboard',
          })}
          description={
            <Col>
              <p>
                <FormattedMessage
                  defaultMessage="Setup your integration"
                  description="homepage/description/integration"
                />
              </p>
              <Link href={`dashboard`}>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    margin: 32,
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Start configuration"
                    description="homepage/description/link"
                  />
                </Button>
              </Link>
            </Col>
          }
        />
      )}

      {/* <div>{JSON.stringify(hikes)}</div> */}

      <IntroBlock
        type="green-bottom"
        title={formatMessage({
          defaultMessage: 'Teamleader - Shopify',
          description: 'homepage/last/title',
        })}
        description={
          <Col>
            <LastIntegrations />
          </Col>
        }
      />
      <IntroBlock
        type="white"
        title={formatMessage({
          defaultMessage: 'Automate your sales workflow',
          description: 'homepage/custom-integrations/title',
        })}
        description={
          <Col>
            <p>
              <FormattedMessage
                defaultMessage="You want to take away repetitive tasks?"
                description="homepage/custom-integrations/description/p1"
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="We make your online and offline sales platforms communicate with each other."
                description="homepage/custom-integrations/description/p2"
              />
            </p>
            <Divider />
            <p>
              <FormattedMessage
                defaultMessage="Our Hitch And Hike platform can enrich the communication and give you some powerful
                extra insights"
                description="homepage/custom-integrations/description/p3"
              />
            </p>
            <Row justify="center" gutter={[8, 8]} style={{ marginBottom: 32 }}>
              {[
                formatMessage({
                  defaultMessage: 'Tracking',
                  description: 'homepage/custom-integrations/extra/1',
                }),
                formatMessage({
                  defaultMessage: 'Logging',
                  description: 'homepage/custom-integrations/extra/2',
                }),
                formatMessage({
                  defaultMessage: 'Alerting',
                  description: 'homepage/custom-integrations/extra/3',
                }),
                formatMessage({
                  defaultMessage: 'Smart Dashboards',
                  description: 'homepage/custom-integrations/extra/4',
                }),
              ].map((text) => (
                <Col key={text} xs={24} md={12} lg={6}>
                  <Card style={{ textTransform: 'uppercase' }} bordered={false}>
                    {text}
                  </Card>
                </Col>
              ))}
            </Row>
            <p>
              <FormattedMessage
                defaultMessage="Easily customisable, trackable, and changeable at all time."
                description="homepage/custom-integrations/description/last"
              />
            </p>
            <Link href={`/hitch/custom`}>
              <Button type="primary" size="large">
                <FormattedMessage
                  defaultMessage="Find out more"
                  description="homepage/custom-integrations/description/link"
                />
              </Button>
            </Link>
          </Col>
        }
      />

      <IntroBlock
        type="green-top"
        title={formatMessage({
          defaultMessage: 'Off the shelve',
          description: 'homepage/off-the-shelve/title',
        })}
        description={
          <Col>
            <p>
              <FormattedMessage
                defaultMessage="We offer simple and ready-made integrations, that can improve your productivity in
                  no-time."
                description="homepage/off-the-shelve/description/p1"
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="They are only one click away. Check them out, and try them for free."
                description="homepage/off-the-shelve/description/p2"
              />
            </p>
            <Link href={'/hitch/off-the-shelf'}>
              <Button type="default" size="large">
                <FormattedMessage
                  defaultMessage="Check them out"
                  description="homepage/off-the-shelve/description/link"
                />
              </Button>
            </Link>
          </Col>
        }
      />

      <IntroBlock
        type="white"
        title={formatMessage({
          defaultMessage: 'Why with us?',
          description: 'homepage/why-us/title',
        })}
        description={
          <>
            <Col md={{ span: 12, order: 2 }} xs={{ order: 2, span: 24 }}>
              <NextImage
                alt="robot"
                src={'/homepage/robot2.png'}
                layout="responsive"
                width="1747"
                height="1240"
              />
            </Col>
            <Col md={{ span: 12, order: 1 }} xs={{ order: 1, span: 24 }}>
              <p>
                <FormattedMessage
                  defaultMessage="Our specific focus on sales platforms makes us an expert in syncing Order management, Contact management, Invoicing, Products and Stock management."
                  description="homepage/why-us/description/p1"
                />
              </p>
              <p>
                <FormattedMessage
                  defaultMessage="We are engineers that are specialised in integrating API's. Because we only do that, we're very efficient in it."
                  description="homepage/why-us/description/p2"
                />
              </p>
              <Link href={`/contact`}>
                <Button type="primary" size="large">
                  <FormattedMessage
                    defaultMessage="Contact us"
                    description="homepage/custom-integrations/description/link"
                  />
                </Button>
              </Link>
            </Col>
          </>
        }
      />

      <IntroBlock
        type="green-bottom"
        title={formatMessage({
          defaultMessage: 'Looking for something specific?',
          description: 'homepage/form/title',
        })}
        description={
          <Col>
            <p>
              <FormattedMessage
                defaultMessage="Contact us, to check if we can match"
                description="homepage/form/description"
              />
            </p>
            <ContactForm />
          </Col>
        }
      />
    </>
  );
};

export default withPublicWrapper(Index);
