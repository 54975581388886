import styles from './intro.module.css';
import { Col, Row } from 'antd';
import ContentContainer from '../ContentContainer/ContentContainer';
import React from 'react';
import Head from 'next/head';
import NextImage from 'next/image';

type NextImageProps = {
  src: string;
  height: string;
  width: string;
  alt: string;
};

type IntroProps = {
  image?: NextImageProps;
  title?: string;
  description?: JSX.Element;
  descriptionText: string;
};
const Intro = ({ title, image, description, descriptionText }: IntroProps): JSX.Element => {
  return (
    <Row align="middle" justify="center" className={styles.intro}>
      <Head>
        <meta property="og:title" content={title} key="ogtitle" />
        <meta property="og:description" content={descriptionText} key="ogdesc" />
      </Head>
      <ContentContainer>
        <Row gutter={[32, 32]} justify="center" align="middle">
          <Col lg={image ? 8 : 24} md={{ span: image ? 12 : 24 }} xs={{ order: 1, span: 24 }}>
            {title && <h1>{title}</h1>}
            {description || (
              <div>
                <p>{descriptionText}</p>
              </div>
            )}
          </Col>
          {image && (
            <Col lg={8} md={{ span: 12 }} xs={{ order: 2, span: 24 }}>
              <NextImage
                alt={image.alt}
                src={image.src}
                layout="responsive"
                width={image.width}
                height={image.height}
              />
            </Col>
          )}
        </Row>
      </ContentContainer>
    </Row>
  );
};
export default Intro;
