import { Button, Col, Input, Modal, Form, Radio } from 'antd';
import React, { useContext } from 'react';
import SearchHitch from 'components/SearchHitch';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';
import * as Sentry from '@sentry/node';
import { createForm } from 'services/public/forms';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

const FormItem = Form.Item;
const { TextArea } = Input;

type ContactFormOptions = {
  withoutApps?: boolean;
  prefilledText?: string;
};
const ContactForm = ({ withoutApps, prefilledText }: ContactFormOptions): JSX.Element => {
  const { push, query } = useRouter();
  const { formatMessage } = useIntl();

  const { user } = useContext(UserAuthenticationContext);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      sm: { span: 24 },
      md: { span: 6 },
      xl: { span: 5 },
    },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 20 },
    },
  };

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      md: { span: 10, offset: 7 },
    },
  };

  const onFinish = async (values: { [key: string]: any }) => {
    try {
      await createForm(values, user);
      form.resetFields();
      push({ query: { ...query, contacted: undefined } });
      Modal.success({
        content: formatMessage({
          defaultMessage: 'Thanks for contacting us',
          description: 'contact/form/success',
        }),
      });
    } catch (e) {
      Modal.error({
        content: formatMessage({
          defaultMessage:
            'Unfortunately we were unable to process this form. Please try again, or send us an email.',
          description: 'contact/form/error',
        }),
      });
      Sentry.captureException(`Unable to save form with data ${JSON.stringify(values)}`);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    throw new Error(errorInfo as string);
  };

  return (
    <div style={{ minWidth: '60vw' }}>
      <Form hideRequiredMark form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <FormItem
          {...formItemLayout}
          label={formatMessage({
            defaultMessage: 'Email',
            description: 'contact/form/mail/label',
          })}
          name="email"
          rules={[
            {
              type: 'email',
              message: formatMessage({
                defaultMessage: 'Please, leave a valid email address',
                description: 'contact/form/mail/valid',
              }),
            },
            {
              required: true,
              message: formatMessage({
                defaultMessage: 'Email is required',
                description: 'contact/form/mail/required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              defaultMessage: 'your@mail.com',
              description: 'contact/form/mail/example',
            })}
          />
        </FormItem>
        {!withoutApps && (
          <SearchHitch
            {...formItemLayout}
            label={formatMessage({
              defaultMessage: 'Interested in',
              description: 'contact/form/app1/label',
            })}
            name="app1"
          />
        )}
        {!withoutApps && (
          <SearchHitch
            {...formItemLayout}
            label={formatMessage({
              defaultMessage: 'To integrate with',
              description: 'contact/form/app2/label',
            })}
            name="app2"
          />
        )}
        <FormItem
          {...formItemLayout}
          label={formatMessage({
            defaultMessage: 'Tell us',
            description: 'contact/form/text/label',
          })}
          name="goal"
        >
          <TextArea
            style={{ minHeight: 32 }}
            placeholder={formatMessage({
              defaultMessage: 'Let yourself go, the more we know, the better we can help you.',
              description: 'contact/form/text/placeholder',
            })}
            defaultValue={prefilledText}
            rows={8}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={formatMessage({
            defaultMessage: 'Stay up to date',
            description: 'contact/form/subscribeType/label',
          })}
          name="subscribeType"
        >
          <Col span={24}>
            <Radio.Group>
              <Radio value="specific">
                {formatMessage({
                  defaultMessage: 'Only concerning my case',
                  description: 'contact/form/subscribeType/option/specific',
                })}
              </Radio>
              <Radio value="all">
                {formatMessage({
                  defaultMessage: 'All updates on the platform',
                  description: 'contact/form/subscribeType/option/all',
                })}
              </Radio>
            </Radio.Group>
          </Col>
        </FormItem>
        <FormItem {...submitFormLayout} style={{ marginTop: 32 }}>
          <Button type="primary" htmlType="submit">
            {formatMessage({
              defaultMessage: 'Submit',
              description: 'contact/form/submit',
            })}
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default ContactForm;
