import { Card, Button } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const { Meta } = Card;

type LastIntegrationCardType = {
  iconAlt: string;
  iconSrc: string;
  title: string;
  description: ReactNode;
  href: string;
};

const LastIntegrationCard = ({
  iconAlt,
  iconSrc,
  title,
  description,
  href,
}: LastIntegrationCardType): JSX.Element => {
  return (
    <Card
      style={{ width: 300 }}
      cover={
        <div style={{ padding: '25%' }}>
          <Image alt={iconAlt} src={iconSrc} layout="responsive" width="400" height="400" />
        </div>
      }
      actions={[
        <Link key="integration-details" href={href}>
          <Button type="link" icon={<ApiOutlined key="hitch" />}>
            Check what can do
          </Button>
        </Link>,
      ]}
    >
      <Meta title={title} description={<div style={{ minHeight: '80px' }}>{description}</div>} />
    </Card>
  );
};

export default LastIntegrationCard;
